import Date from './date';
import Image from './image';
import RadioButtonsGroup from './radioButtonsGroup';
import Text from './text';

export default function GenerateInput({ inputData, onChange, value, ...props }) {
  switch (inputData.type) {
    case 'radio': return <RadioButtonsGroup
      label={inputData.label}
      name={inputData.attributeName}
      value={value}
      onChange={onChange}
      options={inputData.options}
      {...props} />
    case 'date': return <Date
      label={inputData.label}
      name={inputData.attributeName}
      defaultDate={inputData.defaultDate}
      value={value}
      onChange={onChange}
      {...props} />
    case 'image': return <Image
      label={inputData.label}
      name={inputData.attributeName}
      value={value} {...props} />
    default: return <Text
      label={inputData.label}
      name={inputData.attributeName}
      onChange={onChange}
      type={inputData.type}
      maxLength={inputData.maxLength}
      defaultValue={inputData.defaultValue}
      value={value}
      {...props} />
  }
}


