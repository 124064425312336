import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  workflowStarted: false,
  currentWorkflowStep: 0,
  enableConfetti: false
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    changeHomeState: (state, action) => {
      //console.log({ ...state, ...action.payload })
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key]
      })
    },
    manageConfetti: (state, action) => {
      //console.log('action.payload', action.payload)
      state.enableConfetti = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeHomeState, manageConfetti } = homeSlice.actions

export default homeSlice.reducer