/* eslint-disable react-hooks/exhaustive-deps */

import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect, useState } from 'react';
import { createConnection } from '../../api/calls';
import { routes } from '../../api/routes';



//Endpoint and token for SSe call
const URL = `${process.env.REACT_APP_BASE_URL}` + routes.eventStream;


export default function ProgressSSE(props) {
    const { setInvitationUrl, setOpenQrPopup, openQrPopup, setLatestSseResponse,
        disableQrDialogOnThisState, onFinish , endFlowOnThisState} = props;
    const [eventSource, setEventSource] = useState(null)
    const EventSource = EventSourcePolyfill;
    const establishSSE = async () => {
        const UID = await createConnection(props.page, setInvitationUrl, setOpenQrPopup);


        if (props.setUuid) {
            props.setUuid(UID)
        }

        //console.log('${URL}/${UID}', `${URL}/${UID}`)
        if (UID) {
            //creating an instance of eventSource to listen for SSE
            const es = new EventSource(`${URL}/${UID}`, { heartbeatTimeout: 9999999 })

            //console.log("es:", es)
            setEventSource(es)
            es.onopen = function (params) {
                console.log("SSE: OPEN")
                setLatestSseResponse(null)
            }
            es.onmessage = (e) => {
                try {
                    //console.log('SSE response', e)
                    // const decoded = atob(e.data);

                    let response = null
                    try {
                        response = JSON.parse(e.data)
                    } catch (error) {
                        //console.log(error)
                        response = { event: e.data };
                    }
                    console.log('SSE: response', response)
                    // switch (response.event) {
                    //     case 210:
                    //         data.topic = "wallet_credential";
                    //         break;
                    //     case "Proof Presentation":
                    //         setOpenQrPopup(false)
                    //         alert("boom")
                    //         break;
                    //     default:
                    //         break;
                    // }
                    if (response.event !== 'Ping') {
                        setLatestSseResponse(response)
                    }
                    if (response.event === disableQrDialogOnThisState) {
                        setOpenQrPopup(false)
                        setInvitationUrl(null)
                    }
                    if (response.event === endFlowOnThisState) {
                        setOpenQrPopup(false)
                        setInvitationUrl(null)
                        setLatestSseResponse(null)
                        onFinish();
                    }
                    // dispatch(actions.setEvent(data, 1));

                } catch (error) {
                    //console.log(error);
                }
            };


            es.onerror = function (e) {
                //console.log('SSE ERROR: ', e)
                es.close();
                //establishSSE();

            }



            // return () => {
            //          es.close()
            // }

        }

    }


    // useEffect(() => {
    //     if (!listening) {
    //       establishSSE();
    //     }
    // }, [listening, events]); 



    useEffect(() => {

        if (props.checkIfQRClosedManually) {
            props.setCheckIfQRClosedManually(false)
            if (eventSource) {
                eventSource.close();
                setEventSource(null)
            }
        }
        else if (openQrPopup) {
            establishSSE();
        }

    }, [openQrPopup, props.checkIfQRClosedManually]);


    return <></>;
}


