import { Badge, FormControl, Grid, Skeleton } from '@mui/material';



export default function Image({ name,
    label,
    value, ...props
}) {

    return (

        <FormControl variant="standard">
            <Grid shrink htmlFor="bootstrap-input">
                {label}
            </Grid>
            {value ?
                <Badge badgeContent={props.endAdornment} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                    <img
                        style={{ height: '80px' }}
                        src={"data:image/png;base64, " + value}
                        alt="name" />
                </Badge> : <Skeleton variant="rectangular" width={80} height={80} />}
        </FormControl>

    );
}
