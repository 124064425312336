import axios from "axios";
import { v4 } from "uuid";
import { mobileOperatingSystem } from "../helpers/mobileOperatingSystem";
import { routes } from "./routes";

export const baseInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

export const sendCredential = async (data) => {

    return baseInstance.post(routes.sendCredential, data)
        .then(res => {
            //console.log('response', res)
            if (res.data.code === 0) {
                return res.data.data ?? true;
            }
            throw new Error(res.data.message)
        }).catch(err => {
            //console.log('sendCredential', err)
            window.snackbar("error", err.response?.data.message ?? err.message)
        })
}

export const createConnection = async (type, setInvitationUrl, setOpenQrPopup) => {
    const uuid = v4();
    try {
        const data = await baseInstance.post(routes.createInvite,
            { alias: "demo_" + type + "~" + uuid },
            {
                headers: {
                    agenttoken: process.env.REACT_APP_AGENT_TOKEN,
                    // agent token of superAdmin wallet is being used
                    Authorization: process.env.REACT_APP_JWT_TOKEN,
                },
            }
        );

        //  //console.log("TYPE OF DATAAAAA:", data.data.data.uid)

        var responseInvitationURL = data.data.data.invitationUrl;
        var encodedInvitation = responseInvitationURL.split("c_i=")[1];
        // const InvitationWithoutURLAPPENDED = responseInvitationURL.split("c_i=")[1];
        // var DecodedInvitationWithoutURLAPPENDED = atob(InvitationWithoutURLAPPENDED);

        // var response = JSON.parse(DecodedInvitationWithoutURLAPPENDED)

        // switch (type) {
        //   case "national-id":
        //     response.label = "Citizen Service";
        //     response.imageUrl = `${img_urk}/images/national_id.png`
        //     // //console.log("Uidai Image",`${img_urk}/images/national_id.png`)
        //     break;
        //   case "banking":
        //     response.label = "YouWe Bank";
        //     response.imageUrl = `${img_urk}/images/banking.png`;
        //     break;
        //   case "travel":
        //     response.label = "FlyWay Travels";
        //     response.imageUrl = `${img_urk}/images/travel.png`;
        //     break;
        //   case "education":
        //     response.label = "IIT Kanpur";
        //     response.imageUrl = `${img_urk}/images/education.png`;
        //     break;
        //   case "employment":
        //     response.label = "Trential";
        //     response.imageUrl = `${img_urk}/images/employment.png`;
        //     break
        //   case "vaccine":
        //     response.label = "Forever Healthcare";
        //     response.imageUrl = `${img_urk}/images/vaccine.png`;
        //     break;
        //   default:
        //     response.label = "Trential.com"
        //     response.imageUrl = `${img_urk}/images/employment.png`;
        // }
        console.log('responseInvitationURL', responseInvitationURL)
        // var encodedInvitation = btoa(JSON.stringify(response));
        setInvitationUrl(responseInvitationURL)
        // TODO - Deep linking
        if (
            mobileOperatingSystem() === "Android" ||
            mobileOperatingSystem() === "iOS"
        ) {
            // Redirect to any route, append the encodedInvitation
            // window.location.href = "/app.html?param=" + encodedInvitation;
            // <Redirect to='/demo/travel' />


            if (
                document.URL.includes("referrer=holder-app") ||
                localStorage.getItem("referrer") === "true"
            ) {
                // console.log(
                //     "redirecting to",
                //     `trential://payload=${responseInvitationURL}`
                // );
                window.location.href = `trential://payload=${responseInvitationURL}`;
            } else {
                // console.log(
                //     "redirecting to",
                //     `https://deeplink.trential.dev/payload=${responseInvitationURL}`
                // );
                window.location.href = `https://deeplink.trential.dev/payload=${responseInvitationURL}`;
            }

            //  In Case the user clicks on the QR button and then select cancel when redirecting to the mobile app
            if (window.location.href !== `trential://payload=${encodedInvitation}`)
                setOpenQrPopup(false)

            return setInvitationUrl ? data.data.data.uuid : data.data.data;
        }
        //console.log('data.data', data.data)
        return data.data.data.uuid;
    } catch (error) {
        //console.log(error);
    }

};


export const createInvitation = async (type, multiuse, alias) => {


    try {
        const data = await baseInstance.post(
            `?alias=${alias}&auto_accept=true&multi_use=${multiuse}`,
            {},
            {
                // headers: {
                //     agenttoken: agentToken,
                //     // agent token of superAdmin wallet is being used
                //     Authorization: jwtToken,
                // },
                data: {
                    multi_use: false,
                    alias: alias,
                },
            }
        );

        // //console.log("Inside not MobilecreateConnection:", response)

        // dispatch({
        //   type: actionTypes.CREATE_INVITATION,
        //   data: response.data
        // })
        //  //console.log("Connection ID:", data.data.data.invitation.connection_id)
        return data?.data?.data?.invitation;
    } catch (error) {
        //console.log(error);
    }

};


export const createPasswordlessProofReq = (type, multiuse, alias) => {
    return async (dispatch) => {
        try {
            const response = await baseInstance.get(
                `/api/1.0/products/generateProofReq`,
            );

            if (
                mobileOperatingSystem() === "Android" ||
                mobileOperatingSystem() === "iOS"
            ) {
                window.location.href = `/app.html?param=${response.data.data}`;
            }
            return response.data.data;
        } catch (error) {
            throw error;
        }
    };
};

export const registerUser = async (name, email) => {
    try {
        const connection = await createConnection("uidai", false, "CREDENTIAL")

        if (!connection) return;
        const connectionId = connection.connection_id;
        // const invitation_url = JSON.stringify(useSelector(state=>state.common.invitationJSON)).replaceAll('"','');
        baseInstance({
            method: "post",
            url: `/api/1.0/story/signup`,
            data: {
                name,
                email,
                connectionId,
            },
        })
            .then((res) => {
                if (res.data.statusCode === 200) {


                    const payload = connection.invitation_url;

                    sendEmail(name, email, payload);
                } else if (res.data.statusCode === 201) {

                    window.snackbar("User Already Registered", "error")
                } else {
                    window.snackbar("Network Error", "error")
                }
            })
            .catch((err) => {
                //console.log("ERROR", err);
            });
    } catch (error) {
        throw error;
    }

};

export const sendEmail = (name, email, payload, type) => {
    // //console.log("ACTIONS.JS Send Email Called", name, email, payload)
    // const template = `Hey ${name}, thanks for signing up. Following is your invitation link https://ssi.trential.com/demo/app.html?param=${payload}`
    // //console.log("TEMPLATE", template)

    return async (dispatch) => {
        try {
            baseInstance({
                method: "post",
                url: `/api/1.0/send/email`,
                data: {
                    to: email,
                    subject: "[SSI-Trential] Passwordless Sign up ",
                    payload: payload,
                    name: name,
                },
            })
                .then((res) => {
                    // dispatch({
                    //     type:
                    //         type === "resent"
                    //             ? actionTypes.EMAIL_RESENT
                    //             : actionTypes.REGISTER_USER,
                    //     data: true,
                    // });
                    // dispatch({
                    //   type: actionTypes.REGISTER_USER,
                    //   data: true
                    // })
                })
                .catch((err) => {
                    //console.log("ERROR", err);
                });
        } catch (error) {
            throw error;
        }
    };
};