import { ArrowForward } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';

import { config, types } from '../../config/config';
import MarkdownWrapper from '../common/markdownWrapper';
import { motion } from 'framer-motion';


export default function CardContent({ company, sectionToDisplay, isWorkflowPage, onButtonClick }) {


    if (sectionToDisplay?.type === types.TITLE_WITH_BUTTON) {
        return (<Grid container justifyContent={"space-between"} alignItems="center"
            sx={{ flexDirection: 'row', margin: '0' }}>
            <Grid item xs={12} sm={12} md={6} lg={8}>
                <Typography component={'div'} variant="body1" sx={{
                    fontSize: '2rem', fontWeight: 600, lineHeight: 1.2,
                    marginBottom: '0.6em', color: config.pages[company]?.theme ?? '#343D48'
                }}><MarkdownWrapper>{sectionToDisplay.title}</MarkdownWrapper></Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <Button variant="contained" onClick={() => onButtonClick(sectionToDisplay.button.link)}
                    component={motion.div}
                    whileHover={{ scale: 1.1 }}
                    // whileTap={{ scale: 0.95 }}
                    sx={{
                        float: 'right',
                        // fontWeight: 'bold',
                        fontSize: '2rem',
                        minWidth: '150px',
                        padding: '0.2em 2em'
                    }}
                >{sectionToDisplay.button.title ?? ""}
                    <ArrowForward style={{ fontSize: '1em' }} /></Button>
            </Grid>
        </Grid>)
    } else if (sectionToDisplay.type === types.TITLE_WITH_BUTTONS) {
        return (<Grid container justifyContent={"flex-start"} alignItems="flex-start"
            sx={{ flexDirection: 'column' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography component={'div'} variant="body1" sx={{
                    fontSize: '2rem', fontWeight: 600, lineHeight: 1.2,
                    marginBottom: '0.6em', color: config.pages[company]?.theme ?? '#343D48'
                }}><MarkdownWrapper>{sectionToDisplay.title}</MarkdownWrapper></Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {sectionToDisplay.buttons.map((button, i) => <Button
                    key={"sectionToDisplay-buttons-" + sectionToDisplay.title + "-" + i}
                    component={motion.div}
                    whileHover={{ scale: 1.1 }}
                    // whileTap={{ scale: 0.95 }}
                    variant="outlined"
                    onClick={() => onButtonClick(button.link)}
                    sx={{
                        marginRight: 'auto',
                        margin: '1em 1em 1em 0',
                        // fontWeight: 'bold',
                        fontSize: '2rem',
                        minWidth: '150px',
                        padding: '0.2em 2em'
                    }}
                >{button.title ?? ""}</Button>)}
            </Grid>
        </Grid>)
    } else {
        return (
            <>
                <Typography variant="body1" sx={{
                    fontSize: '2rem', fontWeight: 600, lineHeight: 1.2,
                    marginBottom: '0.6em', color: config.pages[company]?.theme ?? '#343D48'
                }}>{sectionToDisplay.title}</Typography>

                <Typography component={'div'} variant="body1" sx={{
                    fontSize: '1.8rem',
                    fontWeight: 500, color: '#343D48'
                }}><MarkdownWrapper>{sectionToDisplay.subtitle}</MarkdownWrapper></Typography>

                {sectionToDisplay.steps ? <ul style={{
                    margin: sectionToDisplay.bulletsForSteps ? '0.8em' : '2em 0',
                    listStyle: sectionToDisplay.bulletsForSteps ? 'inherit' : 'none'
                }}>
                    {sectionToDisplay.steps?.map((step, i) => <li
                        key={"primary-steps-" + i}
                        style={{ margin: sectionToDisplay.bulletsForSteps ? '1em' : '1em 0' }}>

                        <Grid container justifyContent={"flex-start"} alignItems="center"
                            sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
                            <Grid item xs={12} sm={12} md={step.image ? 10 : 12} lg={step.image ? 10 : 12}>
                                <Typography component={'div'} variant="body1" sx={{
                                    fontSize: '1.8rem', fontWeight: 600, lineHeight: 1.2,
                                    marginBottom: '0.6em',
                                    color: '#343D48'
                                }}><MarkdownWrapper>{step.title}</MarkdownWrapper></Typography>

                                <Typography component={'div'} variant="body2" sx={{
                                    fontSize: '1.6rem',
                                    fontWeight: 500, color: '#343D48'
                                }}><MarkdownWrapper>{step.subtitle}</MarkdownWrapper></Typography>
                            </Grid>
                            {step.image ? <Grid item xs={12} sm={12} md={2} lg={2}
                                sx={{ display: { xs: 'none', md: 'inherit' } }}>
                                <img src={process.env.PUBLIC_URL + "images" + step.image} alt="hero"
                                    style={{ height: '6vw', margin: '1em', float: 'right', marginLeft: 'auto' }} />
                            </Grid> : null}
                        </Grid>
                    </li>)}
                </ul> : null}

            </>
        )
    }
}
