import { ArrowForward } from '@mui/icons-material';
import { Button, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import trentialBackground from "../../assets/svg/trentialBackground.svg";
import Applications from '../../components/credential/cards/application';
import Form from '../../components/credential/form';
import HeaderAppBar from '../../components/credential/header';
import ProgressSSE from '../../components/credential/progressSse';
import QRPopup from '../../components/credential/qrPopup';
import CustomDialog from '../../components/customDialog';
import { config, types } from '../../config/config';

export default function UniqueCredentialPage({ company, onButtonClick }) {

    document.title = (config.pages[company]?.title ? (config.pages[company]?.title + " | ") : '')
        + "Get Credential |" + config.title
    const history = useHistory()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [invitationUrl, setInvitationUrl] = useState(null)
    const [openQrPopup, setOpenQrPopup] = useState(false)
    const [uuid, setUuid] = useState(false)
    const [latestSseResponse, setLatestSseResponse] = useState(null)
    const [checkIfQRClosedManually, setCheckIfQRClosedManually] = useState(false);
    const cur = config.pages[company]?.flowScreen ?? {}
    const flowProgress = config.pages[company]?.flowProgress ?? {}



    if (company) {
        return (
            <>

                <Grid container direction="column" justifyContent={"flex-start"} alignItems="center"
                    sx={{
                        minHeight: '100vh', backgroundImage: 'url("' + trentialBackground + '")',
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >

                    <HeaderAppBar company={company} />
                    <Grid container direction="column" justifyContent={"flex-start"} alignItems="flex-start"
                        sx={{ width: { xs: '98%', md: '80%', lg: '80%' }, padding: '1em' }}>

                        {cur?.back ? <Button variant="text" onClick={() => history.push(cur?.back.link)}
                            sx={{
                                marginRight: 'auto',
                                color: 'grey',
                                // fontWeight: 'bold',
                                fontSize: '1.5rem',
                                marginBottom: '1em'
                            }}
                        ><b>{"< "}&nbsp;</b>{cur?.back?.title ?? ""}</Button> : null}

                        <Grid container justifyContent={"flex-start"} alignItems="center"
                            sx={{
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'flex-start' }
                            }}>
                            <Grid item xs={12} sm={12} md={7} lg={6}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: { xs: 'center', md: 'flex-start' },
                                    textAlign: { xs: 'center', md: 'left' }
                                }}>
                                <Typography variant="body1" sx={{ fontSize: { xs: '6vw', sm: '4vw', md: '4rem' }, fontWeight: 600, lineHeight: 1.2 }}>{cur.title}</Typography>
                                {/* <div style={{ backgroundColor: config.pages[company]?.theme, height: '5px', width: "80px", margin: '1em 0' }}></div> */}
                                <Typography variant="body1" sx={{
                                    fontSize: { xs: '3vw', sm: '2vw', md: '1.6rem' }, fontWeight: 600,
                                    //   color: config.pages[company]?.themeSecondary,
                                }}>{cur.subtitle}</Typography>
                                <Button variant="contained"
                                    // disabled={latestSseResponse}
                                    onClick={() => {
                                        // dispatch(manageConfetti(true))
                                        if (cur?.startFlowButton.link) {
                                            history.push(cur?.startFlowButton.link)
                                        }
                                        else {
                                            setOpenQrPopup(true)
                                        }
                                    }}
                                    sx={{
                                        display: { xs: 'none', md: 'flex' },
                                        marginRight: 'auto',
                                        backgroundColor: cur?.startFlowButton.color,
                                        padding: '1em 1.5em',
                                        margin: '1em 0',
                                        fontWeight: 600,
                                        fontSize: '1.8rem',
                                        borderRadius: '8px',
                                    }}
                                >{cur?.startFlowButton.title ?? ""}
                                    <ArrowForward style={{ fontSize: '2em' }} /></Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={6}
                                sx={{ textAlign: 'right' }}>
                                <img src={process.env.PUBLIC_URL + "images" + cur.image} alt="hero"
                                    style={{
                                        height: matches ? '15vw' : '20vw',
                                        margin: '1em', float: 'right', marginLeft: 'auto'
                                    }} />
                            </Grid>
                            <Button variant="contained"
                                disabled={latestSseResponse}
                                onClick={() => {
                                    // dispatch(manageConfetti(true))
                                    if (cur?.startFlowButton.link) {
                                        history.push(cur?.startFlowButton.link)
                                    }
                                    else {
                                        setOpenQrPopup(true)
                                    }
                                }}
                                sx={{
                                    display: { xs: 'flex', md: 'none' },
                                    marginRight: 'auto',
                                    backgroundColor: cur?.startFlowButton.color,
                                    padding: '1em 1.5em',
                                    margin: '1em 0',
                                    fontWeight: 600,
                                    fontSize: '1.8rem',
                                    borderRadius: '8px',
                                }}
                            >{cur?.startFlowButton.title ?? ""}
                                <ArrowForward style={{ fontSize: '2em' }} /></Button>
                        </Grid>

                        {(flowProgress.type === types.FORM &&
                            (latestSseResponse?.event)) ? <Form
                            latestSseResponse={latestSseResponse}
                            flowProgress={flowProgress}
                            setLatestSseResponse={setLatestSseResponse}
                            setOpenQrPopup={setOpenQrPopup}
                            page={config.pages[company]?.credentialInviteId}
                            uuid={uuid}
                            setInvitationUrl={setInvitationUrl}
                        /> : null}

                        {cur?.action?.applications?.length > 0 ? <Grid
                            container direction="column" justifyContent={"flex-start"} alignItems="flex-start">
                            <Typography variant="body1" sx={{ fontSize: '1.8rem', fontWeight: 600 }}>{cur.action.title}</Typography>
                            <Typography variant="body2" sx={{ fontSize: '1.6rem', fontWeight: 500 }}>{cur.action.subtitle}</Typography>
                            <Grid container alignItems="stretch" spacing={2}
                                sx={{ margin: matches ? 0 : '1em -14px', padding: 0, width: '100%' }}>
                                {cur?.action?.applications?.map((application, index) => (
                                    <Applications
                                        index={index}
                                        onButtonClick={onButtonClick}
                                        application={application}
                                        company={company} />))}
                            </Grid>
                        </Grid> : null}
                    </Grid>

                    <ProgressSSE page={config.pages[company]?.credentialInviteId}
                        checkIfQRClosedManually={checkIfQRClosedManually}
                        setCheckIfQRClosedManually={setCheckIfQRClosedManually}
                        setInvitationUrl={setInvitationUrl}
                        setOpenQrPopup={setOpenQrPopup}
                        openQrPopup={openQrPopup}
                        setLatestSseResponse={setLatestSseResponse}
                        setUuid={setUuid}
                        disableQrDialogOnThisState={flowProgress.disableQrDialogOnThisState}
                        endFlowOnThisState={flowProgress.endFlowOnThisState}
                    />

                    {openQrPopup ? <CustomDialog
                        title={openQrPopup === 'success' ? '' : (latestSseResponse ? flowProgress.title : (flowProgress.qrCodeScannerTitle
                            ?? "Scan the QR code from Trential App to connect"))}
                        open={openQrPopup}
                        setOpen={(e) => {
                            setOpenQrPopup(e);
                            if (!e) {
                                setLatestSseResponse(null);
                                setInvitationUrl(null)
                            }
                        }}
                        fullWidth={true}
                        maxWidth={"lg"}
                    >
                        {openQrPopup !== 'success' ? <QRPopup
                            setInvitationUrl={setInvitationUrl}
                            setOpenQrPopup={setOpenQrPopup}
                            invitationUrl={invitationUrl}
                            latestSseResponse={latestSseResponse}
                            setLatestSseResponse={setLatestSseResponse}
                            flowProgress={flowProgress}
                            company={company} /> : <Grid container alignItems="stretch" spacing={2}
                                sx={{ margin: matches ? 0 : '1em -14px', padding: 0, width: '100%' }}>
                            {cur?.action?.applications?.map((application, index) => (
                                <Applications
                                    xs={12} sm={6} md={6} lg={6}
                                    index={index}
                                    onButtonClick={onButtonClick}
                                    application={application}
                                    company={company} />))}
                        </Grid>}
                    </CustomDialog> : null}

                </Grid >

                {/* <Footer color="transparent" /> */}
            </>
        )
    } else {
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress />
        </Grid>
    }
}


