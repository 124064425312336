import { Refresh } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { config } from '../../config/config';
import { changeHomeState } from '../../containers/home/slice';


function HeaderAppBar({ company }) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const pages = config.pages[company]?.flowScreen?.nav ?? []
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    //console.log('config.pages[company]?.flowScreen?.navFontWeight', config.pages[company]?.flowScreen)

    return (
        <AppBar position="static"
            sx={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                backgroundColor: config.pages[company]?.flowScreen?.headerBackgroundColor ?? '#ffffff',
                color: config.pages[company]?.flowScreen?.headerTextColor ?? 'black',
                boxShadow: 'none',
                borderTopLeftRadius: matches ? 0 : '16px',
                borderTopRightRadius: matches ? 0 : '16px',
            }}>
            <Grid
                sx={{ width: { xs: '98%', md: '100%', lg: '100%' }, padding: '0 1em 0 1em' }}>
                <Toolbar disableGutters
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                    <img
                        src={process.env.PUBLIC_URL + "images"
                            + config.pages[company]?.flowScreen.logo}
                        alt="logo"
                        style={{ maxHeight: '4vh' }} />

                    {config.pages[company]?.flowScreen.logoText ? <ListItemText
                        sx={{ marginLeft: '1em' }}
                        primaryTypographyProps={{ sx: { fontWeight: 600, fontSize: '1.8rem' } }}
                        secondaryTypographyProps={{ sx: { fontWeight: 600, color: '#000000' } }}
                        primary={config.pages[company]?.flowScreen.logoText.primary}
                        secondary={config.pages[company]?.flowScreen.logoText.secondary} /> : null}
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="primary"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                            <MenuItem onClick={() => {
                                handleCloseNavMenu();
                                dispatch(changeHomeState({ workflowStarted: false, currentWorkflowStep: 0 }))
                                window.location.replace("/")

                            }}>
                                <Typography textAlign="center">Restart Demo</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                variant="text"
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{
                                    fontFamily: "'Lato'",
                                    fontWeight: config.pages[company]?.flowScreen?.navFontWeight
                                        ? config.pages[company]?.flowScreen?.navFontWeight : 600,
                                    fontSize: "14px",
                                    padding: '0.5em 2em',
                                    color: config.pages[company]?.flowScreen?.headerTextColor ?? 'inherit',
                                }}
                            >
                                {page}
                            </Button>
                        ))}
                        {config.pages[company]?.flowScreen?.showRestartDemoButton ?
                            <Button variant="outlined"
                                onClick={() => {
                                    dispatch(changeHomeState({ workflowStarted: false, currentWorkflowStep: 0 }))
                                    window.location.replace("/")
                                }}
                                sx={{
                                    margin: '0 1em'
                                    // borderRadius: '30px',
                                }}>
                                Restart Demo
                                <Refresh />
                            </Button>
                            : config.pages[company]?.flowScreen?.headerRightLogo ?
                                (<img src={process.env.PUBLIC_URL + "images"
                                    + config.pages[company]?.flowScreen.headerRightLogo}
                                    alt="headerRightLogo"
                                    style={{ maxHeight: '7vh', float: 'right' }} />)
                                : null}
                    </Box>

                </Toolbar>
            </Grid>
        </AppBar>
    );
}
export default HeaderAppBar;
