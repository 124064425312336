
import { useState } from "react";

import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Typography } from "@mui/material";
import QRCode from 'qrcode.react';
import { registerUser } from "../../api/calls";
import checkGreenCircleOutline from '../../assets/svg/checkGreenCircleOutline.svg';
import Text from "../common/generateInput/text";
import CustomDialog from "../customDialog";
const stages = {
    "SIGNIN": {
        title: "Sign in with SSI"
    },
    "SIGNUP": {
        title: 'Sign Up for Password-less Sign In'
    }
}
export default function PasswordLessSignIn(props) {
    const { payloadValue, openQrCode, setOpenQrCode } = props;
    const [stage, setStage] = useState('SIGNIN')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');


    //   const validateEmail=(email)=> {
    //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(String(email).toLowerCase());
    // }

    //console.log('payloadValue, openQrCode', payloadValue, openQrCode)


    return (
        <CustomDialog
            maxWidth="sm"
            fullWidth
            title={stages[stage].title}
            open={openQrCode}
            setOpen={setOpenQrCode}>

            {stage === "SIGNIN" ?
                <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <QRCode
                            value={payloadValue}
                            size={window.screen.width > 1440 ? 270 : 270}
                        />
                        <Typography variant="body2">Scan the QR code from the SSI app and verify Proof Request to sign in</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant="body1">Don't have password-less sign in credential?</Typography>
                        <Button variant="text" onClick={() => setStage('SIGNUP')}>Sign Up</Button>
                    </Grid>
                </Grid> :
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Text
                        sx={{ margin: "1em" }}
                        type="text"
                        name="fullName"
                        value={name}
                        onChange={(v) => setName(v)}
                    />
                    <Text
                        sx={{ margin: "1em" }}
                        type="email"
                        name="emailAddress"
                        value={email}
                        onChange={(v) => setEmail(v)}
                    />

                    {
                        success ?
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <Typography variant="body1" sx={{
                                    display: 'flex', flexDirection: 'row',
                                    justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <img src={checkGreenCircleOutline} alt="check" style={{ margin: '0 5px' }} />
                                    A link has been sent to the email address!
                                </Typography>
                                <Button variant="text" onClick={() => {
                                    registerUser(name, email).then(() => setSuccess(true));
                                }}>Resend</Button>
                            </Grid> :
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                sx={{ borderRadius: '50%', margin: '1em', padding: '20px 10px' }}
                                onClick={async () => {
                                    setLoading(true)
                                    setSuccess(true)
                                    // await registerUser(name, email).then(() => setSuccess(true));
                                    setLoading(false)
                                }}
                            >
                                <ArrowForward /></LoadingButton>
                    }

                </Grid>

            }
        </CustomDialog>

    );
};



