import { Button, Collapse, Grid, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import HomeScreen from '../../components/home';
import { config, types } from '../../config/config';
import theme from '../../config/theme';
import CredentialPage from '../credential';
import UniqueCredentialPage from '../credential/unique';
import LandingPage from '../landing';
import SuccessScreen from '../successScreen';
import { changeHomeState, manageConfetti } from './slice';

export default function Home() {
  const history = useHistory()
  const dispatch = useDispatch();
  const location = useLocation();
  let { companyName } = useParams();
  const [company, setCompany] = useState('')
  const { workflowStarted, enableConfetti } = useSelector(s => s.home)

  useEffect(() => {
    //console.log('location', location)
    //console.log(companyName, location)
    if (companyName) {
      setCompany(companyName)
      if (workflowStarted) {
        let indexOfCurrentFlow = config
          .pages[workflowStarted]?.stepperSteps?.links.findIndex(s => s === "/" + companyName)
        if (indexOfCurrentFlow > -1 && (indexOfCurrentFlow < config
          .pages[workflowStarted]?.stepperSteps?.links.length)) {
          dispatch(changeHomeState({
            currentWorkflowStep: indexOfCurrentFlow
          }))
        }
      }
    } else {
      setCompany(config.defaultPage)
    }

    document.documentElement.style
      .setProperty('--main-color', config.pages[companyName ?? config.defaultPage]?.theme ?? '#1F86DC');

  }, [company, companyName, location])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  const customTheme = createTheme(theme, {
    palette: {
      primary: {
        main: config.pages[company]?.theme ?? '#1F86DC',
      },
    },
  }
  );

  const onButtonClick = (param) => {
    let arg = null; let arg2 = null;

    if (typeof param === "string") {
      arg = param;
    } else {
      arg = param[0]
      arg2 = param[1];
    }

    if (arg === types.START_WORKFLOW) {
      dispatch(changeHomeState({ workflowStarted: arg2, currentWorkflowStep: 0 }))
      window.open(config.pages[arg2]?.pageToOpenUponTutorialStart, '_self')
    } else if (arg.split('/').length >= 2 && config.linksThatResetsTheWorkflow.includes(arg.split('/')[1])) {
      if (workflowStarted) {
        dispatch(changeHomeState({ workflowStarted: false, currentWorkflowStep: 0 }))
      }
      history.push(arg)
    } else if (arg === '/') {
      if (workflowStarted) {
        dispatch(changeHomeState({ workflowStarted: false, currentWorkflowStep: 0 }))
      }
      history.push(arg)
    } else {
      history.push(arg)
    }
  }

  //console.log('location', location)
  const getComponentToRender = () => {
    //console.log('company', company)
    if (config.pages[company]?.type === types.UNIQUE_CREDENTIAL_PAGE) {
      return (<UniqueCredentialPage company={company} onButtonClick={onButtonClick} />)
    } else if (config.pages[company]?.type === types.CREDENTIAL_PAGE) {
      return (<CredentialPage company={company} onButtonClick={onButtonClick} />)
    } else if (config.pages[company]?.type === types.LANDING_PAGE
      || config.pages[company]?.type === types.WORKFLOW_PAGE) {
      return location.state?.success ? <SuccessScreen
        company={company}
        onButtonClick={onButtonClick}
      /> : (<LandingPage company={company}
        isWorkflowPage={config.pages[company]?.type === types.WORKFLOW_PAGE}
        onButtonClick={onButtonClick} />)
    } else {
      return (
        <div><HomeScreen /></div>
      )
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      {enableConfetti ?
        <ReactConfetti
          numberOfPieces={500}
          recycle={false}
          width={document.body.clientWidth}
          height={document.body.scrollHeight}
          onConfettiComplete={() => dispatch(manageConfetti(false))}
        /> : null}
      <Disclaimer />
      {getComponentToRender()}
    </ThemeProvider>
  )
}

function Disclaimer() {

  const [showDisclaimer, setShowDisclaimer] = useState(true)

  useEffect(() => {
    const disclaimerShowed = localStorage.getItem('disclaimerShowed')
    setShowDisclaimer(disclaimerShowed)
  }, [])

  return <Collapse
    sx={{
      zIndex: 999
    }}
    in={Boolean(!showDisclaimer && config.disclaimer)}>
    <Grid
      sx={{
        width: '100%',
        padding: '1em',
        backgroundColor: '#F7F7F7',
        zIndex: 999
      }}
      container direction="row" justifyContent="center" alignItems="center"
    >
      <Grid sx={{
        width: { xs: '98%', md: '80%', lg: '80%' },
        backgroundColor: '#F7F7F7',
        zIndex: 999
      }}
        container direction="row" justifyContent="center" alignItems="center">
        <Typography variant="body1" sx={{ width: '80%', fontSize: '1.4rem' }}>{config.disclaimer?.text}</Typography>
        <Button variant="text"
          onClick={() => {
            localStorage.setItem('disclaimerShowed', true)
            setShowDisclaimer(true)
          }}
          sx={{ fontWeight: 600 }}>
          {config.disclaimer?.buttonText}
        </Button>
      </Grid>
    </Grid>
  </Collapse>
}
