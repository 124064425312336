import { Box, Button, Grid, Typography } from '@mui/material';

import QRCode from 'qrcode.react';
import { useHistory } from "react-router";
import appStoreIconBlack from "../../assets/svg/app-store-icon-black.svg";
import googlePlayIconBlack from "../../assets/svg/google-play-icon-black.svg";
import trentialWalletIconBlue from "../../assets/svg/trential-wallet-logo-blue.svg";
import { config } from '../../config/config';
import CustomDialog from '../customDialog';

export default function Popup(props) {
  const history = useHistory()
  return (
    <CustomDialog
      maxWidth="sm"
      fullWidth
      open={props.show}
      setOpen={props.togglePopup}>
      <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '2.6rem', fontWeight: 600 }}>Let’s get things started</Typography>
      <Typography variant="body1" style={{ textAlign: 'left', fontSize: '1.5rem' }}>
        First, we’ll set you up with our mobile wallet where you can manage your credentials. Think of it as a supercharged digital counterpart to your physical wallet.
      </Typography>
      <Grid container alignItems='center'
        sx={{
          marginTop: '20px',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' }
        }}>
        <Grid item xs={6} sm={4} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {/* <img src={qrCode} alt="QR-code" style={{ maxWidth: '100%' }} /> */}
          <QRCode
            id={'app-qr'}
            value={process.env.REACT_APP_COMPANY_APP_DEEPLINK_LINK}
            size={240}
            level={"L"}
            includeMargin={true}
          />
        </Grid>
        <Grid item xs={12} sm={7} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid container direction="row" justifyContent='center' alignItems='center'>
            <img src={trentialWalletIconBlue} alt="Trential wallet icon" style={{ maxWidth: '100%', marginBottom: '10px', marginRight: '20px' }} />
            <p style={{ textAlign: 'center', fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '10px' }}>Trential Wallet</p>
          </Grid>
          <p style={{ textAlign: 'center', fontSize: '1.3rem', marginTop: '5px' }}>You can scan the QR code or click the button to your preferred store below.</p>

          <Grid container direction="row" justifyContent='space-evenly' alignItems='center' sx={{ marginTop: '10px' }}>

            <img src={appStoreIconBlack}
              alt="App Store icon"
              style={{ maxWidth: '45%', cursor: 'pointer' }}
              onClick={() => window.open(process.env.REACT_APP_COMPANY_APPSTORE_LINK, '_blank')} />

            <img src={googlePlayIconBlack}
              alt="Google Play icon"
              style={{ maxWidth: '45%', cursor: 'pointer' }}
              onClick={() => window.open(process.env.REACT_APP_COMPANY_PLAYSTORE_LINK, '_blank')} />
          </Grid>
        </Grid>
      </Grid>
      <div style={{ width: '100%', height: '1px', backgroundColor: '#DADADA', margin: '20px 0' }}></div>
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
        <Button variant="contained" style={{
          // backgroundColor: '#1F86DC', color: 'white', fontWeight: 'bold',
          // textTransform: 'none', fontSize: '1.2rem'
        }}
          onClick={() => history.push("/" + config.defaultPage)}
        >I have the Trential Wallet</Button>
      </Box>
    </CustomDialog >
  )
}
