/* eslint-disable eqeqeq */
import axios from "axios";

const TIMEOUT_MILLISECONDS = 10 * 60 * 1000;

const axiosPing = axios.create({
  timeout: TIMEOUT_MILLISECONDS,
});

const axiosUserInstance = axios.create({
  baseURL: `${process.env.REACT_APP_USER_BASE_URL}`,
  timeout: TIMEOUT_MILLISECONDS
});

const axiosSSEInstance = axios.create({
  baseURL:`${process.env.REACT_APP_SSE_EVENTS_URL}`,
  timeout: TIMEOUT_MILLISECONDS
})

const baseURLs = {
  verifier: `${process.env.REACT_APP_ACCESS_CONTROL_BASE_URL}`,
  events: `${process.env.REACT_APP_SSE_EVENTS_URL}`,
  userManagement: `${process.env.REACT_APP_USER_BASE_URL}`
}

const axiosRequestInterceptor = (request, setLoading) => {
  if (request?.url !== process.env.REACT_APP_AUTH_TOKEN_URL) setLoading(true);
  // const accessToken = getAccessToken()
  // request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
};

const axiosResponseInterceptor = (response, setLoading, history, showErrorSnackbar, dispatch) => {
  if (response && response?.data?.code !== 0) {
    if (response?.data?.code === -2) {
      // localStorage.removeItem("persist:root");
      window.clearInterval(window.intervalId);
      // history.push("/user/login");
      showErrorSnackbar(response.data.message);
    } else {
      if (response?.data?.message) showErrorSnackbar(response.data.message);
    }
  }
  setLoading(false);
  return response;
};

const axiosCatchError = (error, setLoading, history, showErrorSnackbar, dispatch) => {
  //console.log("axios err",error);
  if(error?.response?.data?.code === -2){
    window.clearInterval(window.intervalId);
    // history.push("/user/login");
  }
  setLoading(false);
  const deviceExits = error.response.status == '409' && error.request.responseURL == 'https://indisi-access-control-apis-dev-7kuqnnxqla-el.a.run.app/api/1.0/devices'
  if(!deviceExits) showErrorSnackbar(error?.response?.data?.message ?? error?.response?.data?.error_description);
  return error
};

export {
    axiosPing,
    axiosUserInstance,
    axiosSSEInstance,
    axiosRequestInterceptor,
    axiosResponseInterceptor,
    axiosCatchError,
    baseURLs
};

