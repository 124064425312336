import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useHistory } from "react-router";
import arrowRight from "../../assets/svg/arrowRight.svg";
import trentialBackground from "../../assets/svg/trentialBackground.svg";
import { config } from '../../config/config';
import Footer from "../footer/index";
import Popup from "./qrPopup";

export default function HomeScreen() {
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory()
  const togglePopup = () => {
    setShowPopup(!showPopup);
  }

  return (
    <div>
      <div style={{
        backgroundImage: `url(${trentialBackground})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
        // position: "absolute",
        // top: 0,
        // left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1
      }}>
        <div></div>
        <Grid sx={{
          width: { xs: '90%', md: '70%', lg: '45%' },
          display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
          <Typography variant="h1" sx={{
            textAlign: "center",
            fontSize: { xs: '10vw', sm: '4vw', md: '4vw', lg: '4vw' },
            // lineHeight: "1.2",
            // marginTop: "-10vh",
            // marginLeft: "2vw",
          }}>Manage your identity securely with ease</Typography>
          
          <Typography variant="body1" sx={{
            textAlign: "center",
            fontSize: { xs: '3vw', sm: '2vw', md: '1vw', lg: '1vw' },
            fontWeight: 600,
            // marginTop: "-0.1vh",
            // color: "#000000",
            // marginLeft: "2vw",
            // textAlign: "left",
          }}>Discover how Verifiable Credentials can simplify your life by trying out the demo.</Typography>
          <Typography variant="body1" sx={{
            textAlign: "center",
            fontSize: { xs: '2.8vw', sm: '1.8vw', md: '0.9vw', lg: '0.9vw' },
            fontWeight: 600,
            // marginTop: "-0.1vh",
            color: "#666666",
            // marginLeft: "2vw",
            // textAlign: "left",
          }}>(The Demo is best viewed on a Laptop)</Typography>
          <Grid container sx={{
            width: '100%',
            display: "flex", marginTop: "2rem",
            justifyContent: 'center', alignItems: 'center'
          }}>
            <Button
              variant="contained"
              sx={{
                padding: "0.5em 2em 0.5em 2em",
                // minWidth: { xs: '100%', sm: '50%', md: '30%' },
                margin: "1em 1em 1em 0",
              }}
              onClick={togglePopup}
            >
              Let's Begin
              &nbsp;
              <img src={arrowRight} alt="arrow-right" />
            </Button>
            <Button
              variant="outlined"
              style={{
                padding: "0.5em 2em 0.5em 2em",
                // minWidth: { xs: '100%', sm: '50%', md: '30%' },
                margin: "1em",
              }}
              onClick={() => window.open(config.trentialSsiOfficialPageLink, "_blank")}
            >
              Know more about us
            </Button>
          </Grid>
        </Grid>
        <Footer />
      </div>

     
      {showPopup && <Popup show={showPopup} togglePopup={togglePopup} />}
    </div>
  );
}