import { SnackbarProvider } from "notistack";
import React from "react";

const notistackRef = React.createRef();

function CustomSnackbar({children}) {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={4000}
      ref={notistackRef}
      onClose={(event, reason, key) => {
        if (reason === "clickaway") {
          notistackRef.current.closeSnackbar(key);
        }
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default CustomSnackbar;
