export const types = {
    CREDENTIAL_PAGE: 'credentialPage',
    UNIQUE_CREDENTIAL_PAGE: 'uniqueCredentialPage',
    LANDING_PAGE: 'landingPage',
    WORKFLOW_PAGE: 'workflowPage',
    SIMPLE: 'simple',
    TITLE_WITH_BUTTON: 'titleWithButton',
    TITLE_WITH_BUTTONS: 'titleWithButtons',
    START_WORKFLOW: 'startWorkflow',
    FORM: 'form',
    CONNECTION_ACTIVE: 'connection_active'
}
export const config = {
    title: ' Trential SSI Demo',
    defaultPage: 'national-id',
    trentialSsiOfficialPageLink: 'https://www.trential.com/digital-credentials',
    theme: '#037FFF',
    linksThatResetsTheWorkflow: ['national-id'],
    disclaimer: {
        text: 'This DEMO website is hosted by Trential. It is to demonstrate the usage of the Trential Wallet mobile app to accept, store & use verifiable credentials.',
        buttonText: 'Dismiss'
    },
    pages: {
        'national-id': {
            type: types.UNIQUE_CREDENTIAL_PAGE,
            title: 'Citizen ID',
            theme: '#315CB7',
            themeShade: '#E6F1FE',
            themeSecondary: '#F9B712',
            flowScreen: {
                logo: '/svg/NationalIdBlue.svg',
                logoText: {
                    primary: 'National Identification Agency',
                    secondary: 'Republic of Wadiya',
                },
                headerBackgroundColor: '#E6F1FE',
                headerRightLogo: '/svg/nationalIdHeader.svg',
                headerTextColor: '#013366',
                nav: ['About Wadiya', 'Ecosystem', 'Contact & Support'],
                // showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "Get your Citizen ID Card",
                subtitle: 'The  Citizen ID Card can be used to prove your identity and avail a wide variety of services',
                startFlowButton: { title: "Get Citizen ID", color: "#315CB7" },
                image: '/svg/NationalID.svg',
                action: {
                    title: 'Already have the Citizen ID?',
                    subtitle: 'Continue to explore...',
                    applications: [{
                        title: 'I want to travel',
                        description: 'You want to travel from Wadiya to New Delhi. For it you would require \n - <b>COVID Vaccination Certificate</b> \n - <b> Flight Ticket</b>',
                        image: "/svg/travelCard.svg",
                        buttonText: 'Plan me a Trip',
                        link: 'travel-workflow'
                    }, {
                        title: 'I need a house',
                        description: 'You just got a Job, and wish to buy your First house. You apply to a Bank for a housing loan.<br> Here are the documents you need.\n - <b>Employment Certificate</b>\n - <b>Loan Approval Certificate</b>\n - <b>Possession Papers</b>',
                        image: "/svg/bankCard.svg",
                        buttonText: 'Get me a house',
                        link: 'house-workflow'
                    },
                        // {
                        //     title: 'Visit House Flow',
                        //     description: 'Use this credential to apply for a house',
                        //     link: '/travel-workflow'
                        // }, {
                        //     title: 'Get Vaccination Certificate',
                        //     description: 'Get your vaccination certificate online accepted all across the world',
                        //     link: '/vaccine'
                        // },
                        // {
                        //     title: 'Get College Degree',
                        //     description: 'Click the button below to avail a service like Degree, ID Card, etc',
                        //     link: '/education'
                        // }
                    ]
                }
            },

            flowProgress: {
                qrCodeScannerTitle: "Get Citizen ID",
                title: "Thanks for connecting with us!",
                subtitle: 'To get Citizen ID, complete the following steps',
                stateToOpenOn: "Connection Active",
                steps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>National Identification Agency</b>',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Fill the form with your details and share them',
                    state: 'Proof Request Verified'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will now get a <b>Credential Offer</b> from <b>National Identification Agency</b>',
                    state: 'Credential Offer Sent'
                }, {
                    label: 'Check the details and <b>accept the offer</b> to get the <b>Citizen ID</b>',
                    state: ''
                }, {
                    label: 'You can now continue to the next step',
                    state: 'Credential Issued'
                }],

            },
            credentialInviteId: "national-id",
        },
        // 'home': {
        //     type: types.LANDING_PAGE,
        //     title: '',
        //     theme: '#1F86DC',
        //     themeShade: '#F0F6FA',
        //     welcomeScreen: {
        //         title: "Welcome!",
        //         subtitle: 'to a decentralised identity exchange system!',
        //         heroButtons: [{ question: '', title: "Lets Begin", link: '/travel-workflow' }],
        //         showPasswordLessLoginButton: false,
        //         heroImage: '/svg/credentialShare.svg',
        //         sections: [{
        //             title: 'What is it?',
        //             subtitle: '<span className="list">This demonstration is crafted to help you understand what a Self-Sovereign Identity (or SSI in short) is. After going through this, you will know the following​-\n 1. What is a digital identity\n 2. What are digital credentials​\n 3. How quickly you can connect with various organizations​\n 4. Your data belongs only to you​\n 5. How complex things can be done easily\n</span>',
        //             bulletsForSteps: false,

        //         }, {
        //             title: 'Some use cases...',
        //             subtitle: '<span className="list"> - Government Identity ​\n - Banking  ​\n - Education  ​\n - Healthcare  ​\n - Employment​ and many more…\n</span>',
        //             bulletsForSteps: false,

        //         },
        //         {
        //             type: types.TITLE_WITH_BUTTON,
        //             title: 'Try the demo yourself to see the power of SSI',
        //             subtitle: '​',
        //             button: { title: "Let's Begin", link: '/travel-workflow' },

        //         }, {
        //             type: types.TITLE_WITH_BUTTONS,
        //             title: 'Our Workflows',
        //             subtitle: '​',
        //             buttons: [{ title: "Get a house", link: '/house-workflow' }, { title: "Go travel", link: '/travel-workflow' }],

        //         }]

        //     },
        // },
        'travel-workflow': {
            type: types.WORKFLOW_PAGE,
            title: 'The Travel Experience',
            subtitle: 'Plan My Trip',
            theme: '#007CD7',
            themeShade: '#F0F6FA',
            pageToOpenUponTutorialStart: '/vaccine',
            successPage: {
                title: "Congratulations on booking your flight tickets",
                subtitle: 'We hope you enjoyed the demo and got to learn how to use Trential Wallet',
                buttons: [{ title: "Try House Loan Flow", link: [types.START_WORKFLOW, 'house-workflow'] },
                { title: "Back to Start", link: '/home' }],
                steps: [{
                    title: 'Share information from different documents',
                    icon: '/svg/successPageStepIcon1.svg'
                }, {
                    title: 'Verify your age range without sharing your exact date of birth',
                    icon: '/svg/successPageStepIcon4.svg'
                }, {
                    title: 'Life is easy using Trential Wallet',
                    icon: '/svg/successPageStepIcon3.svg'
                }],
                closingStatement: 'For any other use case, Reach out to us. <a href="https://www.trential.com/" target="_blank"><b>click here</b></a>',
                images: ['/png/vaccine.png', '/png/flight-booking.png'],
                links: ['/vaccine', '/flight-booking'],
            },
            stepperSteps: {
                steps: ['Get Vaccination Certificate', 'Book your Tickets'],
                links: ['/vaccine', '/flight-booking'],
                stepsCompleted: 0
            }
        },
        'vaccine': {
            type: types.CREDENTIAL_PAGE,
            title: 'MIN Healthcare',
            theme: '#00AEE5',
            themeShade: '#D2ECFF',
            themeSecondary: '#FE7878',
            flowScreen: {
                logo: '/svg/minHealthcare.svg',
                nav: ['Hospitals', 'Treatments', 'Services', 'International Patients'],
                showRestartDemoButton: false,
                // back: { title: "Travel Flow", link: '/travel-workflow' },
                title: "Be safe, get yourself vaccinated Today!",
                // subtitle: 'Travel made easy with digital wallet. Covid-19 guidelines from different government agencies have mandated every traveller to hold a Fully Vaccinated Certificate (VC).',
                startFlowButton: { title: "Get Covid Vaccination Certificate", color: "#00AEE5" },
                image: '/svg/Vaccine.svg',
                jargon: {
                    title: "Our fight against COVID",
                    cards: [{
                        title: "Covid-19 Symptoms",
                        subtitle: 'Find more about the Covid-19 symptoms'
                    }, {
                        title: "Book a Test",
                        subtitle: 'Book a test with your nearest collection point'
                    }, {
                        title: "Track your Report",
                        subtitle: 'Track your reports by clicking here'
                    },]
                },
                // action: {
                //     title: 'Already have a Fully Vaccinated Certificate?',
                //     subtitle: 'Visit the Flyway Travels page to book your tickets.',
                //     applications: [{
                //         title: 'Visit Travel Flow',
                //         description: 'Book your flights online to 50 countries across the world, from anywhere & anytime',
                //         link: '/travel-workflow'
                //     }, {
                //         title: 'Flyway Travels',
                //         description: 'Book your flights online to 50 countries across the world, from anywhere & anytime.',
                //         link: '/flight-booking'
                //     }]
                // }
            },
            flowProgress: {
                qrCodeScannerTitle: "Get Vaccination Certificate",
                title: "Thanks for connecting with us!",
                subtitle: 'To get Vaccine Certificate, complete the following steps',
                stateToOpenOn: 'Proof Request Sent',
                steps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>MIN Healthcare</b>',
                    state: 'Proof Presentation Received'
                }, {
                    label: '<b>Select the credentials you want to share the requested information from using the dropdown and tap Share<b>',
                    info: ['You can share information in a single request using multiple credentials', 'In this request, only the fact that your age is greater than 18 is shared and not the actual year of birth'],
                    state: 'Proof Request Verified'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will now get a <b>Credential Offer</b> from <b>MIN Healthcare</b>',
                    state: 'Credential Offer Sent'
                }, {
                    label: 'Check the details and <b>accept the offer</b> to get the <b>Vaccination Certificate</b>',
                    state: 'Credential Issued'
                }, {
                    label: 'You can now continue to the next step',
                    state: 'Credential Issued'
                }],

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You have received your COVID Vaccination Credential.</b><br><b> You can use it to verify your vaccination status as this document is mandatory for travel.</b>",
                image: '/svg/vaccineCongratulations.svg'
            },
            credentialInviteId: "vaccine",
        },
        'flight-booking': {
            type: types.CREDENTIAL_PAGE,
            title: 'Speed Jet',
            theme: '#C11717',
            themeShade: '#FFF3F3',
            themeSecondary: '#21A7D6',
            flowScreen: {
                logo: '/svg/travelsLogo.svg',
                headerRightLogo: '/svg/travelsRightLogo.svg',
                headerBackgroundColor: '#C11717',
                headerTextColor: '#ffffff',
                nav: ['Hotels', 'Flights', 'Contact & Support'],
                navFontWeight: 400,
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                customElements: {
                    welcomeScreen: "flightBooking1.js",
                    formTopScreen: "flightBooking2.js"
                },
                title: "Speed Jet",
                subtitle: 'Book your flights online to 50 countries across the world, from anywhere & anytime',
                startFlowButton: { title: "Book Ticket" },
                image: '/svg/Travel.svg',
                jargon: {
                    title: "",
                    cards: [{
                        title: "Covid-19 Guidelines",
                        subtitle: 'Find out more about covid-19 travel Guidlines'
                    }, {
                        title: "Explore FlyWay Pass",
                        subtitle: 'Our Pass comes with a variety of benefits. Click here to explore'
                    }, {
                        title: "Discover Offers",
                        subtitle: 'We have slashed the prices for popular holiday destinations. Click here to view'
                    },]
                },
                // action: {
                //     title: '',
                //     subtitle: 'Already have House? Check our services which you can try now',
                //     applications: [{
                //         title: 'Visit House Flow',
                //         description: 'Use this credential to apply for a house',
                //         link: '/house-workflow'
                //     },]
                // }
            },
            flowProgress: {
                type: types.FORM,
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Get Flight Ticket',
                // title: "Thanks for connecting with us!",
                // subtitle: 'To get Vaccine Certificate, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>SpeedJet</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'Select the credentials you want to share the requested information from using the dropdown and tap <b>Share</b>',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>SpeedJet</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Accept the <b>Credential Offer</b> under the <b>Pending Actions</b> section to receive your <b>Flight Ticket</b>',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: 'Great! Your <b>Flight Ticket Credential</b> has been issued. You can check it in the "Wallet" section of the Trential Wallet mobile app.',
                    openOnstate: 'Credential Issued'
                },
                attributesSectionTitle: '',
                formName: 'Flight Booking Form',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Full Name',
                    }, {
                        type: 'text',
                        label: 'Citizen ID',
                        // defaultValue: '11',
                        attributeName: 'Citizen ID',
                    }, {
                        type: 'text',
                        label: 'Gender',
                        attributeName: 'Gender',
                    }, {
                        type: 'text',
                        label: 'Vaccination Details',
                        attributeName: 'Vaccine Name',
                    }, {
                        type: 'text',
                        label: 'Date of Dose',
                        attributeName: 'Date of Dose'
                    }
                    ],
                    infoText: 'Forms can be autofilled using the Trential Wallet using verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [
                    {
                        "name": "Departure Date",
                        value: new Date().toISOString()
                    },
                    {
                        "name": "Depart From",
                        "value": "Wadiya"
                    },
                    {
                        "name": "Going To",
                        "value": "New Delhi"
                    },
                    {
                        "name": "Full Name",
                    },
                    {
                        "name": "Gender",
                    },
                    {
                        "name": "Date of Dose"
                    }
                ]

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You have successfully booked the ticket. Hope you have a wonderful trip.</b>",
                image: '/svg/congratulationsFlight.svg'
                // attributes: { name: 'Full Name', 'gender': 'Gender' },
                // customImage: 'flightBookingCredentialDesign.js',

            },
            credentialInviteId: "travel",
        },
        'banking': {
            type: types.CREDENTIAL_PAGE,
            title: 'JP Mohan Chandra & Co',
            theme: '#9A1919',
            themeShade: 'linear-gradient(91.44deg, #FFD5AE 0%, #FFC1C1 98.28%)',
            themeSecondary: '#000000',
            flowScreen: {
                logo: '/svg/youWeBankLogo.svg',
                headerRightLogo: '/svg/blackSignout.svg',
                nav: ['About Us', 'Careers', 'Support'],
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "<span style=\"color:#000000;\">We help empower your dreams</span>",
                subtitle: "<span style=\"color:#444444;\">Apply for a Loan</span>",
                subtitle2: { text: 'Click the button below and Scan the QR using Trential Wallet to start your loan application procedure', color: '#444444' },
                startFlowButton: { title: "Apply" },
                image: '/svg/Bank.svg',
                jargon: {
                    title: "",
                    cards: [{
                        title: "Open new Account",
                        subtitle: 'Open a New Bank account. Select from Savings, Current or Demat.'
                    }, {
                        title: "Apply for Checkbook",
                        subtitle: 'Easily apply for a new checkbook with a few clicks'
                    }, {
                        title: "Transfer Money",
                        subtitle: 'Transfer money to your loved ones quickly and securely'
                    },]
                },
                // action: {
                //     title: '',
                //     subtitle: 'Already have House? Check our services which you can try now',
                //     applications: [{
                //         title: 'Visit House Flow',
                //         description: 'Use this credential to apply for a house',
                //         link: '/house-workflow'
                //     },]
                // }
            },
            flowProgress: {
                type: types.FORM,
                qrCodeScannerTitle: 'Autofill using Trential Wallet',
                qrCodeScannerTitle2: 'Get Loan Approval Letter',
                // title: "Thanks for connecting with us!",
                // subtitle: 'To get Vaccine Certificate, complete the following steps',
                stateToOpenOn: null,
                disableQrDialogOnThisState: 'Proof Presentation',
                formSteps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>JP Mohan Chandra & Co</b>',
                    state: 'Proof Presentation'
                }, {
                    label: 'Select the credentials you want to share the requested information from using the dropdown and tap <b>Share</b>',
                    info: ['You can share information in a single request using multiple credentials'],
                    state: 'Proof Presentation'
                }],
                steps: [
                    {
                        label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Credential Offer</b> from <b>JP Mohan Chandra & Co</b>',
                        state: 'Credential Offered'
                    }, {
                        label: 'Check the details and <b>accept the offer</b> to get your <b>Loan Approval Letter</b> in your wallet',
                        state: 'Credential Issued'
                    },],
                successDialog: {
                    label: 'Great! Your <b>Possession Certificate Credential<b> has been issued. You can check it in the "Wallet" section of the Trential Wallet mobile app.',
                    openOnstate: 'Credential Issued'
                },
                formName: 'House Loan Application Form',
                formInputs: {
                    app: [{
                        type: 'text',
                        label: 'Name',
                        attributeName: 'Full Name',
                    },
                    {
                        type: 'text',
                        label: 'Citizen ID',
                        // defaultValue: 11,
                        attributeName: 'Citizen ID',
                    }, {
                        type: 'text',
                        label: 'Year of Birth',
                        attributeName: 'Year of Birth',
                    }, {
                        type: 'text',
                        label: 'Address',
                        attributeName: 'Address'
                    }, {
                        type: 'text',
                        label: 'Company Name',
                        attributeName: 'Name of Company'
                    }, {
                        type: 'text',
                        // defaultValue: 1200000,
                        label: 'Salary',
                        attributeName: 'Salary',
                    },
                        // {
                        //     type: 'text',
                        //     maxLength: 3,
                        //     attributeName: 'interestRate',
                        //     endAdornment: "%"
                        // }, 
                    ],
                    manual: [{
                        type: 'text',
                        label: 'Loan Type',
                        defaultValue: 'House Loan',
                        attributeName: 'Loan Type',
                    }, {
                        type: 'text',
                        maxLength: 10,
                        label: 'Loan Amount',
                        defaultValue: '50,00,000',
                        attributeName: 'Loan Amount',
                        startAdornment: "₹"
                    }],
                    infoText: 'Forms can be autofilled using the Trential Wallet using verified data, minimizing the chances of errors or fraud.'
                },
                buildPayloadMap: [
                    // {
                    //     "name": "Loan Amount",
                    // },
                    // {
                    //     "name": "Interest Rate",
                    //     attributeName: "interestRate"
                    // },
                    {
                        "name": "Full Name",
                    },
                    {
                        "name": "Citizen ID",
                    }, {
                        "name": "Year of Birth",
                    },
                    {
                        "name": "Address",
                    },
                    {
                        "name": "Salary",
                    },
                    {
                        "name": "Name of Company",
                    },
                    {
                        "name": "Loan Type",
                    },
                ]

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You have received your Loan Approval Letter.</b><br><br><b>Share this document with your Realtor and buy your dream house.</b>",
                image: '/svg/bankingCongratulations.svg'
            },
            credentialInviteId: "banking",
        },
        'education': {
            type: types.CREDENTIAL_PAGE,
            title: 'Identity University Degree',
            theme: '#E7478D',
            themeShade: '#F0F5FF',
            themeSecondary: '#706EDC',
            flowScreen: {
                logo: '/svg/university.svg',
                nav: ['Campus', 'Faculty', 'Contact & Support'],
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "Welcome to Identity University",
                subtitle: 'Welcome to Identity University digital degree service. Get your digital degree directly into the Trential wallet. ',
                startFlowButton: { title: "Click here" },
                image: '/svg/College.svg',
                jargon: {
                    title: "Our fight against Illiteracy",
                    cards: [{
                        title: "Climate Change",
                        subtitle: 'Bringing change to their communities'
                    }, {
                        title: "Book an Entrance Exam",
                        subtitle: 'Book a test with your nearest examination center'
                    }, {
                        title: "Track your Report",
                        subtitle: 'Track your reports by clicking here'
                    },]
                },
                // action: {
                //     title: '',
                //     subtitle: 'Already have Degree? Check our services which you can try now',
                //     applications: [{
                //         title: 'Visit House Flow',
                //         description: 'Use this credential to apply for a house',
                //         link: '/house-workflow'
                //     }, {
                //         title: 'Apply for a Job',
                //         description: 'Come work with the best tech company in town',
                //         link: '/employment'
                //     }]
                // }
            },
            flowProgress: {
                qrCodeScannerTitle: "Get Degree",
                title: "Thanks for connecting with us!",
                subtitle: 'To get Degree, complete the following steps',
                stateToOpenOn: "Connection Active",
                steps: [{
                    label: 'We have sent you a Credential Offer. View and "Accept" it on the Trential mobile app',
                    state: 'Credential Offer Sent'
                }, {
                    label: 'Great! Your Degree credential is issued to you.You can check it in the "Wallet" of the Trential mobile app.',
                    state: 'Credential Issued'
                }],

            },
            credentialInviteId: "education",
        },
        'employment': {
            type: types.CREDENTIAL_PAGE,
            title: 'Employee Portal',
            theme: '#315CB7',
            themeShade: '#D2ECFF',
            themeSecondary: '#034c96',
            flowScreen: {
                logo: '/svg/company.svg',
                headerRightLogo: '/svg/blackSignout.svg',
                nav: ['About Macrosoft', 'Careers', 'Support'],
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "<span style=\"color:#000000;\">Employee Portal</span>",
                subtitle: '<span style=\"color:#444444;\">Get your Employment Certificate</span>',
                subtitle2: { text: 'Click the button below and Scan the QR using Trential wallet to get your Employment Certificate', color: '#444444' },
                startFlowButton: { title: "Get Employment Certificate" },
                image: '/svg/employment.svg',
                jargon: {
                    title: "",
                    cards: [{
                        title: "Skill Development",
                        subtitle: 'Get certificates for the skill you have'
                    }, {
                        title: "Leave Management",
                        subtitle: 'Manage all your leaves'
                    }, {
                        title: "Get your Salary Slips",
                        subtitle: 'Get all your Salary slips in a single place'
                    },]
                },
                // action: {
                //     title: '',
                //     subtitle: 'Already have Job? Check our services which you can try now',
                //     applications: [{
                //         title: 'Visit House Flow',
                //         description: 'Use this credential to apply for a house',
                //         link: '/house-workflow'
                //     },
                //     {
                //         title: 'Get House',
                //         description: 'Secure Bank, a financial powerhouse, provides banking services',
                //         link: '/banking'
                //     },]
                // }
            },
            flowProgress: {
                qrCodeScannerTitle: "Get Employment Certificate",
                title: "Thanks for connecting with us!",
                subtitle: 'To get your Employment Certificate, complete the following steps',
                stateToOpenOn: "Proof Request Sent",
                endFlowOnThisState: 'Credential Issued',
                steps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>Macrosoft</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Select the credentials you want to share the requested information from using the dropdown and tap <b>Share<b>.',
                    // info: ['You can share information in a single request using multiple credentials', 'In this request, only the fact that your age is greater than 18 is shared and not the actual year of birth'],
                    state: 'Proof Request Verified'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will now get a <b>Credential Offer</b> from <b>Macrosoft</b>.',
                    state: 'Credential Offer Sent'
                }, {
                    label: 'Check the details and <b>accept the offer</b> to get your <b>Employment Certificate</b>.',
                    state: 'Credential Issued'
                }, {
                    label: 'You can now continue to the next step',
                    state: 'Credential Issued'
                }],
            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You now have your Employment Certificate. This can be used for:</b> \n - <b>Loan Application</b> \n - <b>Rent Agreement</b> \n - <b>Employment Verification</b> and many more...",
                image: '/svg/employmentCongratulations.svg'
            },
            credentialInviteId: "employment",
        },
        'housing': {
            type: types.CREDENTIAL_PAGE,
            title: 'DFL Housing',
            theme: '#007CD7',
            themeShade: 'linear-gradient(91.44deg, #E3FFF0 0%, #C1E5FF 98.28%)',
            themeSecondary: '#000000',
            flowScreen: {
                logo: '/svg/housingLogo.svg',
                headerRightLogo: '/svg/blackSignout.svg',
                headerBackgroundColor: '#FAFAFA',
                headerTextColor: '#000000',
                nav: ['About Us', 'Homes', 'Support'],
                showRestartDemoButton: false,
                // back: { title: "Home", link: '/' },
                title: "<span style=\"color:#000000;\">Building for a better</span> future",
                subtitle: 'Join millions of other proud DFL property owners',
                subtitle2: { text: 'Verify your documents to get your Possession Certificate', color: '#444444' },
                startFlowButton: { title: "Verify" },
                image: '/svg/buildings.svg',
                jargon: {
                    title: "",
                    marginTop: '-3em',
                    cards: [{
                        title: "<span style=\"color:#B9A600;font-family:'Bodoni 72';\">Signature</span>",
                        titleCss: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: "center",
                            minHeight: '10vh',
                        },
                        subtitle: '<span style=\"display: block; width: 100%; text-align: end; font-weight:600;color:#000000;\">Exclusively by Invitation</span>'
                    }, {
                        title: "<span style=\"color:#B9A600;font-family:'Bodoni 72';\">Luxury</span>",
                        titleCss: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: "center",
                            minHeight: '10vh',
                        },
                        subtitle: '<span style=\"display: block; width: 100%; text-align: end; font-weight:600;color:#000000;\">Explore</span>'
                    }, {
                        title: "<span style=\"color:#3C5E07;font-family:'Bodoni 72';\">Premium</span>",
                        titleCss: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: "center",
                            minHeight: '10vh',
                        },
                        subtitle: '<span style=\"display: block; width: 100%; text-align: end; font-weight:600;color:#000000;\">Explore</span>'
                    },]
                },
                // action: {
                //     title: '',
                //     subtitle: 'Already have Degree? Check our services which you can try now',
                //     applications: [{
                //         title: 'Visit House Flow',
                //         description: 'Use this credential to apply for a house',
                //         link: '/house-workflow'
                //     }, {
                //         title: 'Apply for a Job',
                //         description: 'Come work with the best tech company in town',
                //         link: '/employment'
                //     }]
                // }
            },
            flowProgress: {
                qrCodeScannerTitle: "Get Possession Certificate",
                title: "Thanks for connecting with us!",
                subtitle: 'To get Posession Certificate, complete the following steps',
                stateToOpenOn: "Connection Active",
                steps: [{
                    label: 'Scan the QR using <b>Trential Wallet</b>.',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will receive a <b>Verification Request</b> from <b>DFL</b>',
                    state: 'Proof Presentation Received'
                }, {
                    label: 'Select the credentials you want to share the requested information from using the dropdown and tap <b>Share<b>',
                    // info: ['You can share information in a single request using multiple credentials', 'In this request, only the fact that your age is greater than 18 is shared and not the actual year of birth'],
                    state: 'Proof Request Verified'
                }, {
                    label: 'Under the <b>Pending Actions</b> section, you will now get a <b>Credential Offer</b> from <b>DFL</b>',
                    state: 'Credential Offer Sent'
                }, {
                    label: 'Check the details and <b>accept the offer</b> to get your <b>Possession Certificate</b>',
                    state: 'Credential Issued'
                }, {
                    label: 'You can now continue to the next step',
                    state: 'Credential Issued'
                }],

            },
            congratulationsScreen: {
                title: 'Congratulations!',
                flexDirection: 'column',
                info: "<b>You have received the Possession Certificate</b>",
                image: '/svg/congratulationsHousing.svg'
                // attributes: { name: 'Full Name', 'gender': 'Gender' },
                // customImage: 'housingCredentialDesign.js',

            },
            credentialInviteId: "housing",
        },
        'house-workflow': {
            type: types.WORKFLOW_PAGE,
            title: 'The House Buying Experience',
            subtitle: 'Get Me A House',
            theme: '#1F86DC',
            themeShade: '#F0F6FA',
            pageToOpenUponTutorialStart: '/employment',
            successPage: {
                title: "Congratulations on getting your new home",
                subtitle: 'We hope you enjoyed the demo and got to learn how to use Trential Wallet',
                buttons: [{ title: "Try our Travel flow", link: [types.START_WORKFLOW, 'travel-workflow'] },
                { title: "Back to Start", link: '/home' }],
                steps: [{
                    title: 'Share information from different documents',
                    icon: '/svg/successPageStepIcon1.svg'
                }, {
                    title: 'Verify your income range without sharing your exact salary',
                    icon: '/svg/successPageStepIcon2.svg'
                }, {
                    title: 'Life is easy using Trential Wallet',
                    icon: '/svg/successPageStepIcon3.svg'
                }],
                closingStatement: 'For any other use case, Reach out to us. <a href="https://www.trential.com/" target="_blank"><b>click here</b></a>',
                images: ['/png/employment.png', '/png/banking.png', '/png/housing.png'],
                links: ['/employment', '/banking', '/housing'],
            },
            stepperSteps: {
                steps: ['Employment Proof', 'Loan Approval', 'House Possession Certificate'],
                links: ['/employment', '/banking', '/housing'],
                stepsCompleted: 0
            },
            welcomeScreen: {
                back: { title: "Home", link: '/' },
                title: {
                    1: "Welcome",
                    2: 'Great!',
                    3: 'Wow! That was quick.',
                    4: 'Yay!'
                },
                subtitle: {
                    1: "to a decentralised identity exchange system!",
                    2: 'You have a college degree from Identity University now',
                    3: 'Your job offer is conveyed and saved in your wallet in the Trential app.',
                    4: 'You have now successfully received a House and its details is saved in Trential app wallet.<br><br>Everything is set now.'
                },
                heroImage: {
                    1: '/svg/credentialShare.svg',
                    2: '/svg/bullseyeWithArrow.svg',
                    3: '/svg/bullseyeWithArrow.svg',
                    4: '/svg/bullseyeWithArrow.svg'
                },
                stepperSteps: {
                    steps: ['Start', 'Get Degree', 'Apply for a Job', 'Get House'],
                    links: ['/education', '/employment', '/banking'],
                    stepsCompleted: 0
                },

            },
        },
    }
}