import { combineReducers, configureStore } from '@reduxjs/toolkit';
import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import {
  FLUSH, PAUSE,
  PERSIST,
  PURGE,
  REGISTER, REHYDRATE,
  persistReducer, persistStore
} from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import App from "./app";
import homeSlice from "./containers/home/slice";
import "./index.css";
import SnackbarUtils from "./utility/snackbarUtils";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}
const rootReducer = combineReducers({
  home: homeSlice
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

let persistor = persistStore(store)

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <SnackbarUtils>
              <App />
            </SnackbarUtils>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();