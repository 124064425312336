import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";


export default function CustomDialog({ title, open, setOpen, children, ...props }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{ borderRadius: '8px' }}
      {...props}
    >
      {/* <DialogTitle id="customized-dialog-title"
        sx={{
          fontSize: 'var(--body-font-size-xlarge)',
          fontWeight: 700,
          display:'flex',
          flexDirection: 'row',
          justifyContent:'space-between',
          alignItems: 'center'
        }} onClose={handleClose}>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              marginLeft: '1em',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle> */}
      {title ? <Typography variant="h2" color="primary" sx={{
        fontSize: '1.5rem',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '1em',
        lineHeight: 'inherit'
      }}  >{title} </Typography> : null}

      <DialogContent>
        {children}
      </DialogContent>
      {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
    </Dialog>
  );
}
