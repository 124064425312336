import { TextField } from '@mui/material';

export default function Text({ name,
    label,
    type = "text",
    maxLength = false,
    onChange = () => { },
    value,
    startAdornment,
    endAdornment = null,
    ...props
}) {
    const handleChange = (event) => {
        if (maxLength) {
            event.target.value = event.target.value.toString().slice(0, maxLength)
        }
        onChange(event.target.value)
    };

    return (
        <TextField fullWidth
            sx={{ marginRight: '1em' }}
            variant={"outlined"}
            inputProps={{
                readOnly: props.readOnly
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={
                {
                    startAdornment: startAdornment ?? null,
                    endAdornment: endAdornment ?? null
                }
            }
            id={name}
            label={label}
            type={type}
            value={value ?? props.defaultValue ?? ""}
            onChange={handleChange}

            {...props} />
    );
}
