import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { camelCaseToString } from '../../../helpers';

export default function RadioButtonsGroup({ name,label, options, onChange, value, readOnly, ...props }) {

    //console.log('value', value)
    const handleChange = (event) => {
        if (!readOnly) {
            onChange(event.target.value)
        }
    };
    return (
        <FormControl fullWidth {...props}>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name={name}
                value={value}
                onChange={handleChange}
                sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems:'center' }}
            >
                {options.map((option) =>
                    <FormControlLabel key={"option-" + option}
                        value={option}
                        control={<Radio
                            checked={value === option}
                            onChange={handleChange}
                            value={option}
                        />}
                        label={camelCaseToString(option)} />
                )}
            <span>{props.endAdornment }</span>
            </RadioGroup>
        </FormControl>
    );
}