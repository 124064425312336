import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";
import MarkdownWrapper from '../../components/common/markdownWrapper';
import WorkflowStepper from '../../components/credential/workflowStepper';
import { config } from '../../config/config';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { useState } from 'react';
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";

function SuccessScreen({ company, onButtonClick }) {

  document.title = (config.pages[company]?.title ? (config.pages[company]?.title + " | ") : '')
    + config.title


  const theme = useTheme()
  const history = useHistory()
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  // store controlled swiper instance
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const successPage = config.pages[company]?.successPage ?? {};
  const { workflowStarted } = useSelector(s => s.home)
  const activeWorkflow = config.pages[workflowStarted]?.welcomeScreen



  if (successPage) {
    return (
      <div style={{
        textAlign: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden', minHeight: '100vh'
      }}>
        {activeWorkflow ? <WorkflowStepper company={company} /> : null}
        <h1 style={{ color: "#007CD7", marginTop: "5vh", fontSize: "3rem" }}>{successPage?.title}</h1>
        <p style={{ color: "#007CD7", fontSize: "1.5rem", marginTop: "2vh" }}>{successPage?.subtitle}</p>


        {successPage?.steps?.length > 0 ? <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            flexWrap: 'nowrap', width: '80%',
            marginTop: '2em'
          }}
        >
          {successPage?.steps?.map((step, i) => <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            key={"success-page" + i}
          >
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <img src={process.env.PUBLIC_URL + "images" + step.icon} alt=""
                style={{ width: '50px', height: '50px' }} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Typography variant='body2' sx={{ textAlign: 'left' }}>{step.title}</Typography>
            </Grid>
          </Grid>)}
        </Grid> : null}





        <Grid container direction="row" justifyContent={"center"} alignItems="center"
          sx={{ marginTop: "3vh" }}>
          {
            successPage?.buttons?.length > 0 ? successPage?.buttons.map((item, i) => <Button
              key={"successPage-buttons-" + i}
              variant="contained"
              sx={{ margin: "1em" }}
              onClick={() => onButtonClick(item.link)}>{item.title}</Button>) : null
          }

        </Grid>
        <p style={{ fontSize: "1.5rem", marginTop: "2vh" }}>
          <MarkdownWrapper>
            {successPage?.closingStatement}
          </MarkdownWrapper>
        </p>

        <div style={{ margin: "1em", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Swiper
            onSwiper={setControlledSwiper}
            navigation={true}
            effect={"coverflow"}
            slideToClickedSlide={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              scale: 1,
              stretch: -150,
              depth: 400,
              modifier: 1,
              slideShadows: false,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            pagination={true}
            modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
            className="mySwiper"
          >
            {successPage?.images?.length > 0 ? successPage?.images?.map((item, i) => <Grid
              key={"success-images-x-" + (i * 2)}
              style={{ position: "relative", width: i % 2 === 0 ? "32vw" : "34vw", transform: "scale(1.3)" }}>
              <SwiperSlide style={{
                // border: "2px solid #000000", borderRadius: "10px",
                width: matches ? '75%' : "65%"
                // transform: 'scale(0.5)'
              }}>
                <img
                  // onMouseEnter={() => {
                  //   controlledSwiper.slideTo(i)
                  // }}
                  className="swiper-image"
                  onClick={(e) => {
                    history.push(successPage.links[i])
                  }}
                  src={process.env.PUBLIC_URL + "images"
                    + item}
                  alt={`Frame`}
                  style={{
                    border: "2px solid #000000",
                    borderRadius: "10px",
                    width: "100%",
                    cursor: 'pointer'
                    // transform: 'scale(0.5)'
                  }}
                /></SwiperSlide>
            </Grid>) : null}
          </Swiper>
        </div>
      </div>
    );
  }
}

export default SuccessScreen;
