import { Check, Refresh } from '@mui/icons-material';
import { Button, Grid, Link, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { config } from '../../config/config';
import { changeHomeState } from '../../containers/home/slice';

export default function WorkflowStepper({ company }) {
    const dispatch = useDispatch();
    const history = useHistory()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const { currentWorkflowStep, workflowStarted } = useSelector(s => s.home)

    const activeWorkflow = config.pages[workflowStarted]

    const breadcrumbs = [
        <Link underline="hover" key="1" href="/" sx={{ fontSize: '1rem', color: '#444444', margin: 0 }}>
            Home
        </Link>,
        <Link
            underline="hover"
            key="2"
            href={config.defaultPage}
            sx={{ fontSize: '1rem', color: '#444444', margin: 0 }}
        //   onClick={handleClick}
        >
            Citizen ID
        </Link>,
        <Typography key="3" sx={{ fontSize: '1rem', color: '#444444', margin: 0 }}>
            {config.pages[workflowStarted].subtitle}
        </Typography>,
    ];

    console.log('currentWorkflowStep', currentWorkflowStep, activeWorkflow?.stepperSteps.links, company)
    return <Grid
        container justifyContent="space-between" alignItems="center"
        sx={{
            width: '100%',
            padding: '1em',
            backgroundColor: '#EEF2FB',
            flexWrap: 'nowrap',
            flexDirection: { xs: 'column', md: 'row' }
        }}>
        <Grid item xs={12} md={3}>
            <Typography variant='h1' sx={{ color: '#444444', textAlign: 'left' }}>{activeWorkflow.title}</Typography>
            {/* <Breadcrumbs sx={{ margin: 0, marginTop: '0.4em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                separator={<NavigateNext fontSize="small" sx={{ margin: 0 }} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs> */}
        </Grid>
        <Stepper
            activeStep={currentWorkflowStep}
            alternativeLabel
            sx={{
                width: '100%', margin: 0
            }
            }>
            {activeWorkflow?.stepperSteps.steps?.map((label, i) => (
                <Step key={label}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        history.push(activeWorkflow?.stepperSteps?.links[i])
                    }}>
                    <StepLabel StepIconComponent={StepIcon}
                        sx={{
                            '&	.MuiStepLabel-label': {
                                // color: activeWorkflow?.stepperSteps.links[i] === "/" + company ? "#000000 !important" : '#000000',44
                                color: '#000000',
                                fontSize: '1.6rem',
                                fontWeight: 600, textDecoration: "none",
                                cursor: 'pointer'
                            },
                        }}
                    ><b>{label}</b></StepLabel>
                </Step>
            ))}
        </Stepper>
        <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Button variant="outlined"
                color="trential"
                onClick={() => {
                    dispatch(changeHomeState({ workflowStarted: false, currentWorkflowStep: 0 }))
                    window.location.replace("/")
                }}
                sx={{
                    margin: '1em',
                    backgroundColor: '#ffffff',
                    // borderRadius: '30px',
                }}>
                Restart Demo
                <Refresh />
            </Button>
        </Grid>
    </Grid>
}


function StepIcon(props) {
    const { active, completed, className, icon } = props;
    //console.log('props', props);
    return (
        <div className={"circle " + className}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', alignItems: 'center',
                backgroundColor: (active || completed) ? '#037FFF' : '#9E9E9E',
                cursor: 'pointer'
                // color: !active ? '#037FFF' : '#fff',
                // border: '1px solid #037FFF'
            }}>
            {completed ? <Check sx={{ margin: 0, padding: 0 }} /> : <b>{icon}</b>}
        </div>
    );
}