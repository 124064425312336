import { Grid, Typography } from '@mui/material'
import trentialLogo from '../../assets/svg/trential-logo-black.svg'
import tretialFooterText from '../../assets/svg/footer-trential-text.svg'

export default function Footer({ color }) {
  return (
    <Grid
      container
      sx={{
        margin: 0,
        padding: '1em',
        // backgroundColor: 'white',
        // height: '6vh',
        // position: 'fixed',
        bottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Grid
        item
      >
        <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'left' }}>
          <img src={trentialLogo} alt="logo" style={{ height: '17px' }} />
        </Typography>
        <Typography variant="body2" sx={{ display: 'inline-flex', alignItems: 'left', marginLeft: '1rem' }}>
          <img src={tretialFooterText} alt="logo" style={{ height: '17px' }} />
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          margin: 0,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="#707070"
            sx={{ marginBottom: '8px' }}>CONTACT US</Typography>
          <Typography variant="body2">+91 91493 38300</Typography>
          <Typography variant="body2">info@trential.com</Typography>
        </Grid>

      </Grid>

    </Grid>
  )
}
