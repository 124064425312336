/* eslint-disable no-unused-vars */

import { Close } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from "react-router";
import { axiosCatchError, axiosRequestInterceptor, axiosResponseInterceptor } from "./api/axios.js";
import './app.css';
import Home from "./containers/home/index";
const App = ({ classes }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showErrorSnackbar = useCallback((errorMessage) => {
    enqueueSnackbar(errorMessage ? errorMessage : "Something went wrong.", {
      variant: "error",
    });
  }, [enqueueSnackbar]);

  const setLoading = useCallback((load) => {
    // dispatch({
    //   type: AlertActionTypes.SET_LOADING,
    //   data: load,
    // });
  }, []);


  useEffect(() => {

    window.snackbar = (variant, msg) => {
      //console.log(msg);
      if (msg) {
        console.info('in snackbar')
        return enqueueSnackbar(
          msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1)),
          {
            variant,
            style: { overflowWrap: "anywhere", zIndex: "9999999 !important" },
            className: "snackbar",
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            action: (key) => (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeSnackbar(key)}
              >
                <Close fontSize="small" />
              </IconButton>
            ),
          }
        );
      }
    };

    //axios interceptors
    const requestInterceptor = axios.interceptors.request.use((request) =>
      axiosRequestInterceptor(request, setLoading)
    );
    const responseInterceptor = axios.interceptors.response.use(
      (response) =>
        axiosResponseInterceptor(
          response,
          setLoading,
          history,
          showErrorSnackbar,
          dispatch
        ),
      (error) => axiosCatchError(error, setLoading, history, showErrorSnackbar, dispatch)
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch, history, setLoading, showErrorSnackbar]);



  return (
    <Switch>
      <Route path={`/:companyName`}>
        <Home />
      </Route>
      <Route path="*">
        <Redirect to="/home" />
      </Route>
    </Switch>
  );
};

export default withRouter(App);
