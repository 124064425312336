
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { alpha, styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export default function Date({ name,label, defaultDate, value, onChange, ...props }) {


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                style={{ width: '100%' }}
                {...props}
                inputFormat="DD-MM-YYYY"
                value={value ?? defaultDate}
                onChange={(newValue) => {
                    onChange(newValue)
                }}
                renderInput={(params) =>
                    <TextField {...params} fullWidth
                        sx={{ marginRight: '1em' }}
                        variant={"outlined"}
                        inputProps={{
                            readOnly: props.readOnly
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={
                            {
                                startAdornment: props.startAdornment ?? null,
                                endAdornment: props.endAdornment ?? null
                            }
                        }
                        id={name}
                        label={label}
                        {...props} />} />
        </LocalizationProvider>
    );
}
