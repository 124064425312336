import { ArrowForward } from '@mui/icons-material';
import { Button, Card, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { config, types } from '../../../config/config';
import MarkdownWrapper from '../../common/markdownWrapper';


export default function Applications({ index, onButtonClick, application, company, xs = 12, sm = 12, md = 6, lg = 6 }) {
    const cur = config.pages[company]?.flowScreen ?? {}
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} key={"application-" + index}
            sx={{ display: 'flex' }}>
            <Card
                raised
                sx={{
                    border: '1px solid #DADADA',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    // backgroundColor: config.pages[company]?.themeShade ?? 'white',
                    padding: '1.2em',
                    width: '100%',
                    // minHeight: '200px',
                    cursor: 'pointer'
                }}
                onClick={() => onButtonClick([types.START_WORKFLOW, application.link])}
            >
                <Typography variant="body1" color="primary" sx={{
                    fontSize: '1.8rem',
                    fontWeight: 600, lineHeight: 1.2
                }}>{application.title}</Typography>
                <div style={{
                    backgroundColor: config.pages[company]?.theme,
                    height: '2px', width: "32px", margin: '0.6em 0 1em'
                }}></div>


                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={6}><Typography
                        variant="body2"
                        sx={{
                            fontSize: '1.4rem',
                            fontWeight: 500
                        }}>
                        <MarkdownWrapper>
                            {application.description}</MarkdownWrapper></Typography></Grid>
                    <Grid item xs={12} sm={6} sx={{
                        display: "flex", flexDirection: 'column',
                        alignItems: "center",
                    }}>

                        <img src={process.env.PUBLIC_URL + "images" + application.image} alt="card"
                            style={{ maxHeight: matches ? '20vw' : '9vw' }} />

                        <Button variant="contained"
                            onClick={() => onButtonClick([types.START_WORKFLOW, application.link])}
                            sx={{
                                // marginRight: 'auto',
                                marginTop: '1em',
                                // minWidth: 0,
                                backgroundColor: cur?.startFlowButton.color,
                                padding: '0.5em',
                                // fontWeight: 'bold',
                                borderRadius: application?.buttonText ? '8px' : '50%',
                            }}
                        >{
                                application?.buttonText ?
                                    application?.buttonText : <ArrowForward
                                        style={{ fontSize: '2em', margin: 0 }} />
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}
